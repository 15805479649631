import React from "react";
import LayoutWrapper from "../component/LayoutWrapper/Index";
import Blogs from "../component/Blog/index";
const Blog = () => {
  return (
    <LayoutWrapper
      title="Real Estate Insights & Tips | Selby Team San Diego Blog"
      desc="Explore The Selby Teams San Diego blog for the latest trends, tips, and insights in real estate. Stay informed with expert advice tailored for you."
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <Blogs />
    </LayoutWrapper>
  );
};

export default Blog;
