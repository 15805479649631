import React, { useContext, useEffect } from "react";
import Pagination from "../Pagination/Pagination";
import MoveYourFamily from "../Home/MoveYourFamily/MoveYourFamily";
import RealStateSuccess from "./RealStateSuccess/RealStateSuccess";
import ModalContext from "../context/ModalContext";

const Blogs = () => {
  const { setModalvisible, setPopupContent } = useContext(ModalContext);
  useEffect(() => {
    const timer = setTimeout(() => {
      setModalvisible(true);
      setPopupContent("newsletter");
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <div className="bg-[#fff]">
        <RealStateSuccess />
        {/*}   <Pagination /> */}
        <MoveYourFamily />
      </div>
    </>
  );
};

export default Blogs;
