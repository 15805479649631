import React, { useContext } from "react";
import Button from "../../Button/Button";
import { Link } from "gatsby";
import ModalContext from "../../context/ModalContext";

const MoveYourFamily = (props) => {
  const { setModalvisible, setPopupContent } = useContext(ModalContext);

  return (
    <>
      <div className="md:pt-[40px]  bg-[#F7F9FA]">
        <div className="bg-[#F7F9FA] pt-[72px] w-full relative bg-[url('/images/family_bg.png')] md:bg-[url('')] custom_bg sm:pt-[0] overflow-hidden">
          <div className="container">
            <div className="flex items-center justify-between lg:pt-[0px] md:flex-col">
              <div className="max-w-[535px] w-full z-[1] before:content[*] before:absolute before:w-[1007px] before:bg-no-repeat before:h-[498px] before:bg-[url('/images/family_bg.png')] before:bg-contain before:top-[0] before:z-[-1] before:left-0 lg:before:w-[929px] lg:before:h-[400px] md:before:hidden">
                <h3 className="text-d-2xl text-[#1A1A1A] font-IbarraRealNova font-700 pb-[32px]">
                  Let’s Move Your Family
                  <br />
                  <span className="font-Quicksand font-500"> Forward</span>
                </h3>
                <p className="text-d-3lg text-[#666666] font-Quicksand pb-[32px] font-500">
                  {props?.sectionData
                    ? props.sectionData.description
                    : "Connect with a top-rated team of San Diego realtors who listen, are readily available, and will work tirelessly for you."}
                </p>
                <div className="mb-107 flex gap-[16px] sm:mb-80 2xs:mb-32 lg:mb-[73px] 2xs_extar:max-w-[100%] w-full">
                  <Link to="tel:619-251-0979" className=" 2xs_extar:max-w-[48%] 2xs_extar:w-full">
                    <Button type="ghost" customStyle="px-[41px] 2xs_extar:px-[0] 2xs_extar:w-full">
                      <p>619-251-0979</p>
                    </Button>
                  </Link>
                  <Link to="#" className=" 2xs_extar:max-w-[48%] 2xs_extar:w-full">
                    <Button
                      onClick={() => {
                        setModalvisible(true);
                        setPopupContent("steps");
                      }}
                      type="solid"
                      customStyle="px-[53px] 2xs_extar:px-[0] 2xs_extar:w-full"
                    >
                      <p>Contact</p>
                    </Button>
                  </Link>
                </div>
              </div>
              <div
                className="before:content[*] before:absolute before:bg-[url('/images/move_forward.png')] 
            before:bg-contain before:bg-no-repeat before:right-[-72px] before:w-[1074px] before:h-[498px] before:right-0 before:top-[-45px] lg:before:w-[800px] lg:before:h-[400px] md:before:hidden"
              ></div>
            </div>
          </div>
          <div className="custom_set_bg bg-[url('/images/move-your-family.png')] bg-no-repeat w-full h-[320px] hidden md:block relative bg-center"></div>
        </div>
      </div>
    </>
  );
};

export default MoveYourFamily;
