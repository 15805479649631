import { Link } from "gatsby";
import React from "react";
import { blogList } from "../../../data/blogs";
// import Img from "gatsby-image"

const RealStateSuccess = () => {
  //   const data = useStaticQuery(graphql`
  //   query {

  //     placeholderImage: file(relativePath: { eq: "blog_inn.png" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 300) {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //   }
  // `)
  return (
    <>
      <div className="pt-[130px] pb-[127px] md:pt-[170px] md:pb-[70px] sm:pt-[30px]">
        <div className="container">
          <h1 className="text-d-2xl font-IbarraRealNova font-[700] mb-[80px] sm:text-d-xl sm:mb-[30px]">
            Selby Secrets to Real Estate{" "}
            <span className="text-d-3xl sm:text-d-xl font-[500] font-Quicksand"> Success</span>
          </h1>

          <div className="flex gap-[40px] md:flex-col">
            <div className="2xs_extar:px-[20px] xs:px-[0px]">
              <Link to={`/blog${blogList[0].url}`}>
                <img
                  // src="../../../images/The Selby Team_Real Estate_ How To Capitalize On High Interest Rates.jpg"
                  src={blogList[0].img}
                  alt="blog"
                  className="mb-[24px] md:w-full max-w-[768px]"
                />
              </Link>
              <p className="text-d-2lg mb-[8px] text-[#B2B2B2] uppercase font-Quicksand">
                {blogList[0].category}
              </p>
              <Link to={`/blog${blogList[0].url}`}>
                <p className="text-sm-5lg font-Quicksand font-[600] sm:text-d-4lg">
                  {blogList[0].title}
                </p>
              </Link>
            </div>
            <div className="flex flex-col gap-[60px] md:gap-[40px] md:flex-row 2xs_extar:flex-col 2xs_extar:px-[20px] 2xs_extar:gap-[40px] xs:px-[0px]">
              {blogList.slice(1, 3).map((value) => {
                return (
                  <>
                    <div className="max-w-[373px] w-full md:max-w-[100%] md:w-full">
                      {/* <div className="mb-[24px] md:w-full">
                    <Img fluid={data.placeholderImage.childImageSharp.fluid}  />

                    </div> */}
                      <Link to={`/blog${value.url}`}>
                        <img src={value.img} alt="blogimg" className="mb-[28px] md:w-full" />
                      </Link>
                      <p className="text-d-2lg mb-[8px] text-[#B2B2B2] font-Quicksand uppercase font-[700]">
                        {value.category}
                      </p>
                      <Link to={`/blog${value.url}`}>
                        <p className="text-sm-5lg font-Quicksand font-[600] sm:text-d-4lg mb-[8px]">
                          {value.title}
                        </p>
                      </Link>
                    </div>
                  </>
                );
              })}
            </div>
          </div>

          <div className="pt-[60px] 2xs_extar:pt-[40px]">
            <div className="flex flex-wrap gap-x-[40px] gap-y-[60px] 2xs_extar:px-[20px] 2xs_extar:gap-y-[40px] xs:px-[0px]">
              {blogList.slice(3).map((value) => {
                return (
                  <>
                    <div className="" id="blogCard">
                      {/* <div className="mb-[24px] md:w-full">
                    <Img fluid={data.placeholderImage.childImageSharp.fluid} />

                    </div> */}
                      <Link to={`/blog${value.url}`}>
                        <img src={value.img} alt="blogimg" className="mb-[28px] md:w-full" />
                      </Link>
                      <p className="text-d-2lg mb-[8px] text-[#B2B2B2] font-Quicksand uppercase font-[700]">
                        {value.category}
                      </p>
                      <Link to={`/blog${value.url}`}>
                        <p className="text-sm-5lg font-Quicksand font-[600] sm:text-d-4lg mb-[8px]">
                          {value.title}
                        </p>
                      </Link>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RealStateSuccess;
